export const environment = {
  baseUrl: 'http://localhost:4200',
  nextApiBaseUrl: 'https://api-stg.newnext.krungthai.com',
  dglApiBaseUrl: 'https://apigw-dgl-lfm-stg.th-service.co.in',
  production: false,
  mode: 'stg',
  version: 'stg3.3.16',
  tagId: false,
  noCache: false,
};
