import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HttpService } from '@web-di-ld/apps/http';
import { UiLoadingService } from 'libs/apps/http/src/lib/ui-loading.service';
import { filter, map, shareReplay } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { pathUrl } from './configs';
import { FLOW_STATE } from './core/interfaces';
import { DialogErrorHandlerService } from './shared/error-handler/dialog-error-handler.service';

@Component({
  selector: 'web-di-ld-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class AppComponent {
  navigateEnd$ = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd)
  );
  urlChange$ = this.navigateEnd$.pipe(map((e) => e.urlAfterRedirects));
  titleHeader$ = this.urlChange$.pipe(
    map((path) => this.titleHeader(path)),
    shareReplay(1)
  );
  productType$ = this.httpService.productType$;
  applicationRef$ = this.httpService.applicationRef$;
  lang = 'th';
  pathUrl = pathUrl;
  share = 'false';

  constructor(
    public location: Location,
    private router: Router,
    public uiLoadingService: UiLoadingService, // private route: ActivatedRoute, // private cookieService: CookieService
    private httpService: HttpService,
    private dialogErrorService: DialogErrorHandlerService,
    private appState: AppStateService,
    public translocoService: TranslocoService
  ) {
    this.lang = this.translocoService.getActiveLang().toLocaleLowerCase();
  }
  handleClick($event: any) {
    // console.log($event)
  }
  titleHeader(path = this.location.path()) {
    const selLoanProduct = this.appState.snapshot.loanProduct;
    const state = this.appState.snapshot.flow?.state;
    const promotion = this.appState.snapshot.promotion;

    // todo: set-up ais consent properly
    // console.log('titleHeader.productShelf > ', this.location.path(), this.getPath());
    // if (this.location.path() === pathUrl.aisKtbTermAndCon) {
    //   // return 'AIS x KTB';
    //   return ' ';
    // }

    if (promotion && path === pathUrl.promotion) {
      if (promotion.urlToShare) {
        this.share = 'true';
      }
      return promotion.title;
    }
    if (
      selLoanProduct &&
      (path === pathUrl.loanApply.apply ||
        path === pathUrl.loanApply.topupDetail ||
        path === pathUrl.loanApply.topupRequestCredit)
    ) {
      return selLoanProduct.productName;
    }

    if (path === pathUrl.loanApply.topupAdditional) {
      return state !== FLOW_STATE.TOPUP_CONFIRM
        ? selLoanProduct.productName
        : 'titleHeader.editAdditionalInformation';
    }

    this.share = 'false';
    switch (path) {
      case pathUrl.loanApply.apply:
        return 'titleHeader.applyLoan';
      case pathUrl.loanApply.productDetail:
        return 'titleHeader.productDetail';
      case pathUrl.loanApply.calculation:
        return 'titleHeader.calculation';
      case pathUrl.loanApply.topup:
        return 'titleHeader.topup';
      case pathUrl.loanApply.topupDetail:
        return 'titleHeader.topup';
      case pathUrl.loanApply.topupAdditional:
        return 'titleHeader.topup';
      case pathUrl.loanApply.topupConfirm:
        return 'titleHeader.confirmingDetails';
      case pathUrl.personalInformation:
        return 'titleHeader.personalInformation';
      case pathUrl.additionalInformation:
        return 'titleHeader.additionalInformation';
      case pathUrl.editInformation:
        return 'titleHeader.editInformation';
      case pathUrl.confirmingDetails:
        return 'titleHeader.confirmingDetails';
      case pathUrl.marketingConsent:
        return 'titleHeader.consent';
      case pathUrl.ncbConsent:
        return 'titleHeader.consent';
      case pathUrl.aisKtbTermAndCon:
        return 'titleHeader.aisKtbTermAndCon';
      case pathUrl.aisOtp:
        return 'titleHeader.aisOtp';
      case pathUrl.aisConsent:
        return 'titleHeader.consent';
      case pathUrl.confirmSummary:
        return 'titleHeader.confirmSummary';
      case pathUrl.editMailingAddress:
        return 'titleHeader.editInformation';
      default:
        return '';
    }
  }

  ngOnInit() {
    this.dialogErrorService.errorLoaded.subscribe(() => {
      this.dialogErrorService.gaTagging();
    });
  }
}
