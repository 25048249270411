import { BusinessInformation } from './edit-info.interface';
import {
  IProductDetail,
  LoanApplication,
  ProductDetail,
  TopupLoanApplication,
} from './loan-apply.interface';
import {
  AdditionalInformation,
  CDDInformation,
  PersonalInformation,
} from './personal.interface';
import { MarketingConsent } from './marketing-consent.interface';
import { ILoanOffers, IProductShelf } from './product-shelf.interface';
import { IFlow } from './flow.interface';
import { IDraft, IMailingAddress } from './draft.interface';
import { IPromotion, ITemplate } from './promotion.interface';
import { IPromotionBanner } from './promotion-banner.interface';

export interface Month {
  label: string;
  value: number;
}

export interface Option {
  name: string;
  code: string;
  inactive?: boolean;
  visible?: boolean;
}

export interface DisbursementAccount {
  accountNo: string;
  branchCode: string;
  branch: string;
  costCenter: string;
  productGroup: string;
  openDate: string;
}

export interface DirectDebitAccount {
  accountNo: string;
  branchCode: string;
  branch: string;
  costCenter: string;
  productGroup: string;
  openDate: string;
}

export interface CustomerDetail {
  payrollAccount: string;
  bankBranchName?: string;
  bankBranchNameForSubmission?: string;
  bankBranchCode: string;
  costCenter: string;
  titleTh: string;
  titleEn: string;
  fullNameTh: string;
  firstNameTh: string;
  middleNameTh: string;
  surnameTh: string;
  fullNameEn: string;

  firstNameEn: string;
  middleNameEn: string;
  surnameEn: string;

  dateOfBirth: string;
  cid: string;
  mobileNumber: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingAddress3: string;
  mailingAddress4: string;
  mailingSubDistrictCode: string;
  mailingSubDistrictName?: string;
  mailingDistrictCode: string;
  mailingDistrictName?: string;
  mailingProvinceCode: string;
  mailingProvinceName?: string;
  mailingPostalCode: string;
  mailingCountry: string;
  officeAddress1: string;
  officeAddress2: string;
  officeAddress3: string;
  officeAddress4: string;
  officeSubDistrictCode: string;
  officeSubDistrictName: string;
  officeDistrictCode: string;
  officeDistrictName: string;
  officeProvinceCode: string;
  officeProvinceName: string;
  officePostalCode: string;
  officeCountry: string;
  officeName: string;
  officeSector: string;
  officeSectorDescription?: string;
  email: string;
  gender: string;
  nationality: string;
  isExisting: boolean;
}
export interface Consent {
  marketingConsent: any;
  ncbConsent: any;
  ocpbConsent: any;
  aisConsent: any;
  applicationRef: string;
  version: any;
}

export interface Application {
  consent: Consent;
  loanApplication: LoanApplication;
  personalInformation: PersonalInformation;
  businessInformation: BusinessInformation;
  additionalInformation: AdditionalInformation;
  CDDInformation: CDDInformation;
  productDetail: IProductDetail;
  customerDetail: CustomerDetail;
  loanOffers: ILoanOffers;
  loanProduct: IProductShelf;
  flow: IFlow;
  draft: IDraft;
  promotion: ITemplate;
  promotionBanner: IPromotionBanner;
  smartPromo: IPromotionBanner;
  topupLoanApplication: TopupLoanApplication;
  mailingAddress: IMailingAddress;
}

export interface AddressDescription {
  subdistrictName: string;
  districtName: string;
  provinceName: string;
  postcode: string;
}

export interface IBaseResponse {
  code: string;
  message: string;
}
